import React from "react";
import logotm from "../assets/img/logotm.png";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin } from "react-icons/fa";
import facebook from "../assets/img/facebook.png";
import youtube from "../assets/img/youtube.png";
import linkedin from "../assets/img/linkedin.png";

const footer = () => {
  return (
    <section style={{ background: "#f0f8ff" }}>
      <div className="overflow-hidden container xl:py-2 lg:py-2 md:py-2 py-2 mx-auto">
        <div className="xl:block lg:hidden md:hidden hidden">
          <div className="lg:flex xl:flex md:flex-none  xl:px-24 px-4 justify-evenly">
            <div className="text-blue-700  xl:py-8 xl:text-3xl xl:w-4/12 lg:w-3/12">
              <img
                src={logotm}
                className="xl:w-40 lg:w-40 md:w-40 w-40 xl:h-12 lg:h-12 md:h-12 h-12"
              />
              <div className="text-black mt-6 xl:w-full ">
                <p className=" xl:text-base" style={{ fontFamily: "Figtree" }}>
                  At TrawelMart, we are passionate about creating unforgettable
                  travel experiences for our clients. Founded on the principles
                  of exceptional service and personalized travel planning, we
                  are dedicated to turning your dream vacations into reality.
                </p>
              </div>
            </div>

            <div className=" text-blue-700 font-bold xl:px-6 lg:px-2 py-4 xl:text-3xl xl:mt-12 xl:w-3/12 lg:w-3/12 w-full">
              <div className="text-black border-l-4">
                <p
                  className="xl:pl-4 pl-4 font-semibold mt-8 pb-2 xl:text-xl text-xl pt-0"
                  style={{ fontFamily: "Figtree" }}
                >
                  Call Us
                </p>
                <p className="xl:pl-4 pl-4 flex items-center font-semibold xl:text-base ">
                  <FaPhoneAlt
                    className="text-blue-700"
                    style={{ marginRight: "8px" }}
                  />
                  <a
                    href="tel:08046427999"
                    className="text-black xl:text-xl lg:text-lg"
                    style={{ fontFamily: "Figtree" }}
                  >
                    080 4642 7999
                  </a>
                </p>
              </div>
            </div>

            <div className=" text-blue-700 font-bold py-4 xl:text-3xl xl:mt-12 xl:w-3/12 lg:w-3/12 w-full">
              <div className="text-black border-l-4">
                <p
                  className="xl:pl-4 pl-4 font-semibold mt-8 pb-2 xl:text-xl pt-0 text-xl"
                  style={{ fontFamily: "Figtree" }}
                >
                  Mail Us
                </p>
                <p className="xl:pl-4 lg:pl-1 pl-0 flex items-center font-semibold xl:text-base">
                  <FaEnvelope
                    className="text-blue-700"
                    style={{ marginRight: "8px" }}
                  />
                  <a
                    href="mailto:enquiry@trawelmart.com"
                    className="text-black xl:text-xl lg:text-lg"
                    style={{ fontFamily: "Figtree" }}
                  >
                    enquiry@trawelmart.com
                  </a>
                </p>
              </div>
            </div>

            <div className=" font-bold py-4 xl:text-xl xl:mt-12 xl:w-3/12 lg:w-3/12 w-full xl:px-4">
              <div className="text-black border-l-4">
                <p
                  className="text-black font-semibold mt-8 pb-2 xl:text-xl xl:pl-4 pl-4 text-xl"
                  style={{ fontFamily: "Figtree" }}
                >
                  Connect With Us
                </p>

                <div className='flex justify-left items-center mt-2 xl:pl-4 pl-4'>
                  <a href="https://www.facebook.com/trawelmartdotcom" target="_blank" rel="noopener noreferrer" className=''>
                    <FaFacebook size={23} className='mx-2 text-blue-700 ' />
                  </a>
                  <a href="https://www.instagram.com/trawel_mart/" target="_blank" rel="noopener noreferrer">
                  <img src='https://i.ibb.co/PrvQGrc/instagram.png' className='w-5 h-5 mx-2 '></img>
                  </a>
                  <a href=" https://www.youtube.com/@TRAWELMARTWORLDTOURS" target="_blank" rel="noopener noreferrer">
                    <FaYoutube size={23} className='mx-2 text-blue-700' />
                  </a>
                  <a href="https://www.linkedin.com/company/trawel-mart/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin size={23} className='mx-2 text-blue-700' />
                  </a>
                  <a href="https://x.com/i/flow/login?redirect_after_login=%2FTrawel_Mart" target="_blank" rel="noopener noreferrer">
                    <img src='https://i.ibb.co/VgqBhpD/twitter.png' className='w-5 h-5 mx-2 '></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tab and mobile view */}
        <div className="xl:hidden lg:block md:block block">
          <div className="lg:flex-none xl:flex md:flex-none flex-none md:w-12/12 xl:px-28 px-4 justify-evenly">
            <div className="text-blue-700  xl:py-8 xl:text-3xl xl:w-12/12 lg:w-12/12 w-12/12">
              <img
                src={logotm}
                className="xl:w-40 lg:w-40 md:w-40 w-40 xl:h-12 lg:h-12 md:h-12 h-12"
              />
              <div className="text-black mt-6 xl:w-full ">
                <p className=" xl:text-base" style={{ fontFamily: "Figtree" }}>
                  At TrawelMart, we are passionate about creating unforgettable
                  travel experiences for our clients. Founded on the principles
                  of exceptional service and personalized travel planning, we
                  are dedicated to turning your dream vacations into reality.
                </p>
              </div>
            </div>

            <div className="md:flex flex-none md:w-12/12 w-12/12">
              <div className="md:w-4/12 w-12/12 text-blue-700 font-bold xl:px-6 lg:px-2 xl:text-3xl xl:mt-12 ">
                <div className="text-black border-l-4">
                  <p
                    className="xl:pl-4 pl-4 font-semibold mt-8 pb-2 xl:text-xl text-xl pt-0"
                    style={{ fontFamily: "Figtree" }}
                  >
                    Call Us
                  </p>
                  <p className="xl:pl-4 pl-4 flex items-center font-semibold mt-4 xl:text-base ">
                    <FaPhoneAlt
                      className="text-blue-700"
                      style={{ marginRight: "8px" }}
                    />
                    <a
                      href="tel:08046427999"
                      className="text-black xl:text-xl lg:text-lg"
                      style={{ fontFamily: "Figtree" }}
                    >
                      080 4642 7999
                    </a>
                  </p>
                </div>
              </div>

              <div className="md:w-4/12 text-blue-700 font-bold  xl:text-3xl xl:mt-12  w-full">
                <div className="text-black border-l-4">
                  <p
                    className="xl:pl-4 pl-4 font-semibold mt-8 pb-2 xl:text-xl pt-0 text-xl"
                    style={{ fontFamily: "Figtree" }}
                  >
                    Mail Us
                  </p>
                  <p className="xl:pl-4 lg:pl-4 pl-4 flex items-center font-semibold mt-4 xl:text-base">
                    <FaEnvelope
                      className="text-blue-700"
                      style={{ marginRight: "8px" }}
                    />
                    <a
                      href="mailto:enquiry@trawelmart.com"
                      className="text-black xl:text-xl lg:text-lg"
                      style={{ fontFamily: "Figtree" }}
                    >
                      enquiry@trawelmart.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="md:w-4/12 font-bold pb-4 xl:text-xl xl:mt-12  w-full">
                <div className="text-black border-l-4">
                  <p
                    className="text-black font-semibold mt-8 pb-2 xl:text-xl xl:pl-4 pl-4 text-xl"
                    style={{ fontFamily: "Figtree" }}
                  >
                    Connect With Us
                  </p>

                  <div className='flex justify-left items-center mt-4 xl:pl-4 pl-4'>
                  <a href="https://www.facebook.com/trawelmartdotcom" target="_blank" rel="noopener noreferrer" className=''>
                    <FaFacebook size={23} className='mx-2 text-blue-700 ' />
                  </a>
                  <a href="https://www.instagram.com/trawel_mart/" target="_blank" rel="noopener noreferrer">
                  <img src='https://i.ibb.co/PrvQGrc/instagram.png' className='w-5 h-5 mx-2 '></img>
                  </a>
                  <a href=" https://www.youtube.com/@TRAWELMARTWORLDTOURS" target="_blank" rel="noopener noreferrer">
                    <FaYoutube size={23} className='mx-2 text-blue-700' />
                  </a>
                  <a href="https://www.linkedin.com/company/trawel-mart/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin size={23} className='mx-2 text-blue-700' />
                  </a>
                  <a href="https://x.com/i/flow/login?redirect_after_login=%2FTrawel_Mart" target="_blank" rel="noopener noreferrer">
                    <img src='https://i.ibb.co/VgqBhpD/twitter.png' className='w-5 h-5 mx-2 '></img>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex justify-center text-black py-4 border-t text-sm font-semibold"
          style={{ fontFamily: "Figtree" }}
        >
          Copyright © 2024 Trawelmart. All Rights Reserved.
        </div>
      </div>
    </section>
  );
};

export default footer;
